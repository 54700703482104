import * as Sentry from '@sentry/react';
import { SENTRY_DSN, BACKEND_URL } from './constants';

export const initializeSentry = () => {
  try {
    if (!SENTRY_DSN) {
      console.warn('Sentry DSN not found. Error tracking will be disabled.');
      return;
    }

    const tracePropagationTargets = [
      'localhost',
      /^https:\/\/app\.parea\.ai/, // app.parea.ai and its subpages
      /^https:\/\/parea\.ai/, // main domain,
      'https://parea-ai-backend-us-9ac16cdbc7a7b006.onporter.run', // Porter backend
    ];

    // Add backend URL to trace propagation if it exists
    if (BACKEND_URL) {
      try {
        const backendUrl = new URL(BACKEND_URL);
        tracePropagationTargets.push(backendUrl.origin);
      } catch (e) {
        console.warn('Invalid BACKEND_URL format for Sentry trace propagation');
      }
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.extraErrorDataIntegration(),
      ],
      environment: process.env.NODE_ENV,
      tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.0,
      tracePropagationTargets,
      enabled: process.env.NODE_ENV === 'production',
      beforeSend(event) {
        // Don't send events in development
        if (process.env.NODE_ENV === 'development') {
          return null;
        }
        return event;
      },
    });
  } catch (error) {
    console.error('Failed to initialize Sentry:', error);
  }
};
