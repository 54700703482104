import * as Sentry from '@sentry/react';

export const sendSentryError = (error, context = {}) => {
  // try {
  //   if (process.env.NODE_ENV === 'development') {
  //     console.error('Error caught:', error);
  //     console.debug('Error context:', context);
  //     return;
  //   }

  //   Sentry.withScope((scope) => {
  //     // Add any additional context
  //     Object.entries(context).forEach(([key, value]) => {
  //       scope.setExtra(key, value);
  //     });

  //     Sentry.captureException(error);
  //   });
  // } catch (sentryError) {
  //   console.error('Failed to send error to Sentry:', sentryError);
  //   console.error('Original error:', error);
  // }
  return;
};
